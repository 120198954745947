import React, { useState } from "react";
import { ExistingOrToCreateItem } from "../../lib/manage-kontent-item";
import { useClientContext } from "../ClientProvider";
import { Actions } from "./actions";
import { Loading } from "./loading";
import { PreviewModal } from "./preview-modal";
import { SelectLanguages } from "./select-languages";
import { SelectOverwrite } from "./select-overwrite";
import "./style.scss";

export type CopyStatus = "idle" | "loading" | "preview";

export const DeepCopy: React.FC = () => {
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [overwrite, setOverwrite] = useState<boolean>(false);
  const [translateItems, setTranslateItems] = useState<boolean>(false);
  const [status, setStatus] = useState<CopyStatus>("idle");
  const { currentLang } = useClientContext();
  const [existingItems, setExistingItems] = useState<ExistingOrToCreateItem[]>(
    []
  );
  const [contentItemsToCreate, setContentItemsToCreate] = useState<
    ExistingOrToCreateItem[]
  >([]);

  return (
    <div className="copy-parent">
      <SelectLanguages
        selectedLanguages={selectedLanguages}
        handleToggleLanguage={(lang) => toggleLanguage(lang)}
      />
      <SelectOverwrite handleChange={setOverwrite} value={overwrite} />
      <div className="overwrite-parent">
        <div className="option__pane">
          <input type="checkbox" className="option__input--hidden" />
          <label
            onClick={() => setTranslateItems((prev) => !prev)}
            className={`option option--is-checkbox ${
              translateItems && "option--is-selected"
            }`}
          >
            <span className="option__label">
              Translate the text content of the element(s) to target
              language(s)?
            </span>
          </label>
        </div>
      </div>
      <Actions
        selectedLanguages={selectedLanguages}
        overwrite={overwrite}
        translateItems={translateItems}
        setStatus={setStatus}
        setExistingItems={setExistingItems}
        setContentItemsToCreate={setContentItemsToCreate}
      />
      {status === "preview" && currentLang && (
        <PreviewModal
          languages={selectedLanguages}
          overwrite={overwrite}
          existingItems={existingItems}
          contentItemsToCreate={contentItemsToCreate}
          currentLang={currentLang}
          handleRequestClose={closeModal}
        />
      )}
      {status === "loading" && <Loading />}
    </div>
  );

  function closeModal() {
    setStatus("idle");
    resetItems();
  }

  function resetItems() {
    setExistingItems([]);
    setContentItemsToCreate([]);
  }

  function toggleLanguage(codename: string) {
    if (selectedLanguages.includes(codename)) {
      setSelectedLanguages(selectedLanguages.filter((l) => l !== codename));
    } else {
      setSelectedLanguages([...selectedLanguages, codename]);
    }
  }
};
