import React from "react";
import { useClientContext } from "./ClientProvider";
import { DeepCopy } from "./deep-copy";
import "./Main.scss";

export const Main: React.FC = () => {
  // const { status, client } = useClientContext();
  const client = useClientContext();
  return (
    <div
      className="container"
      data-disabled={client.status === "disabled" ? "" : undefined}
    >
      {client.status === "enabled" && <DeepCopy />}
    </div>
  );
};
