import React, { useState } from "react";
import { useClientContext } from "../../ClientProvider";
import "./style.scss";

type SelectLanguagesProps = {
  handleToggleLanguage: (language: string) => void;
  selectedLanguages: string[];
};

export const SelectLanguages: React.FC<SelectLanguagesProps> = ({
  handleToggleLanguage,
  selectedLanguages,
}) => {
  const [status, setStatus] = useState<"idle" | "expanded">("idle");
  const { languages } = useClientContext();

  return (
    <div className="multi-select-parent">
      <p>This is local version with translations</p>
      <p className="u-spacing-l body-medium-emphasis">
        Select language(s) to copy current content into:
      </p>
      <div
        className="multi-select"
        onClick={() =>
          setStatus((val) => (val === "expanded" ? "idle" : "expanded"))
        }
      >
        <div className="multi-select__option-area">
          <div className="multi-select__option-wrapper">
            {selectedLanguages.map((lang) => (
              <div
                className="multi-select__option multi-select__option--product"
                key={lang}
              >
                <div className="multi-select__option-name">{lang}</div>
                <button
                  className="multi-select__option-remove-button"
                  onClick={() => handleToggleLanguage(lang)}
                >
                  <i className="icon-times"></i>
                </button>
              </div>
            ))}
          </div>

          <span className="sr-only">Select options</span>
        </div>
        <div className="multi-select__drop-down-actions">
          <div className="multi-select__drop-down-expand">
            <i
              className="multi-select__drop-down-icon icon-chevron-down"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>
      {status === "expanded" && (
        <>
          <div className="multi-select__dropdown">
            <div className="multi-select__dropdown-options">
              {languages.map((language) => (
                <div
                  className="multi-select__dropdown-option"
                  data-active={
                    selectedLanguages.includes(language.codename)
                      ? ""
                      : undefined
                  }
                  onClick={() => handleToggleLanguage(language.codename)}
                  key={language.codename}
                >
                  <div className="multi-select__dropdown-option-name">
                    {`${language.name}`}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="close" onClick={() => setStatus("idle")} />
        </>
      )}
    </div>
  );
};
